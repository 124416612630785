export default {
  cmsApiUrl: import.meta.env.VITE_CMSAPI_URL,
  gameApiUrl: import.meta.env.VITE_GAMEAPI_URL,
  images: {
    baseUrl: import.meta.env.VITE_IMAGES_BASE_URL,
    cdnUrl: import.meta.env.VITE_IMAGES_CDN_URL,
    imagesRoot: '/',
  },
  useConsoleLogForErrors: import.meta.env.DEV,
  nightQuizUrl: import.meta.env.VITE_NIGHT_QUIZ_URL,
  websocketUrl: import.meta.env.VITE_WEBSOCKET_URL,
  mapboxToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
  mapboxTheme: import.meta.env.VITE_MAPBOX_THEME,
  test: process.env.VITE_CMSAPI_URL,
  godotmapfrBaseURl: import.meta.env.VITE_GODOTMAPFR_BASE_URL,
  cryptoSecretKey: import.meta.env.VITE_CRYPTO_SECRET_KEY,
  cryptoIVKey: import.meta.env.VITE_CRYPTO_IV_KEY,
  salt: import.meta.env.VITE_SALT,
};
