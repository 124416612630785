import cfg from '@/config';
import http from '../utils/fetch';

const authenticate = async (email, password) => {
  const url = `${cfg.cmsApiUrl}/api/v3/auth/editor`;
  const res = await http.postJSON(url, { email, password });
  return res;
};

export default authenticate;
