<script>
import config from '@/config';
import * as Sentry from '@sentry/vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const errorNotify = (message, error = null) => {
  if (error !== null) {
    if (config.useConsoleLogForErrors === true) {
      console.log(`${message}; ${error}`);
    } else {
      Sentry.captureException(error);
    }
  }
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2500,
    clearOnUrlChange: false,
  });
};

export const successNotify = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2500,
    clearOnUrlChange: false,
  });
};
</script>
