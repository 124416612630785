import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import authenticate from '@/api/auth';
import { fetchDestinations } from '@/api/destination';
import { fetchPois } from '@/api/poi';
import { errorNotify } from '@/utils/toastNotifications.vue';
import { OrganizationById } from '@/api/organization';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import config from '@/config';

const useAppStore = defineStore('app', () => {
  const router = useRouter();

  const encryptedEditor = ref(null);
  const currentEditor = ref(null);
  const currentOrganization = ref(
    useStorage('current-organization', {}, localStorage, {
      mergeDefaults: true,
    }),
  );
  const authenticated = ref(useStorage('authenticated', false));
  const destinationsList = ref(useStorage('destinations-list', []));
  const currentDestination = ref(useStorage('destination-current', {}));
  const poisList = ref(
    useStorage('pois-list', {}, localStorage, {
      mergeDefaults: true,
    }),
  );
  const wsIsDisconnected = ref(false);
  // Icebreaker
  const versusUsedQuestionsList = ref(useStorage('usedQuestionsByPartyId', {}));
  // chat ith players
  const chatGlobalMessages = ref(useStorage('globalMessagesByParty', {}));
  const chatMessagesRead = ref(useStorage('chatMessagesReadByParty', {}));

  const encrypteData = (data) => {
    if (!data) {
      return null;
    }
    const key = CryptoJS.PBKDF2(config.cryptoSecretKey, config.salt, {
      keySize: 256 / 32,
      iterations: 100,
    });
    const iv = CryptoJS.enc.Utf8.parse(config.cryptoIVKey);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  };

  const decrypteData = (data) => {
    if (!data) {
      return null;
    }
    const key = CryptoJS.PBKDF2(config.cryptoSecretKey, config.salt, {
      keySize: 256 / 32,
      iterations: 100,
    });
    const iv = CryptoJS.enc.Utf8.parse(config.cryptoIVKey);
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(data) },
      key,
      { iv, mode: CryptoJS.mode.CBC },
    );
    const decodedData = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decodedData);
  };

  const setCurrentEditor = () => {
    const editor = Cookies.get('currentEditor');
    currentEditor.value = decrypteData(editor);

    if (!currentEditor.value) {
      authenticated.value = false;
      return;
    }
    authenticated.value = true;
  };

  const clearApp = () => {
    encryptedEditor.value = null;
    Cookies.remove('currentEditor');
    authenticated.value = false;
    currentOrganization.value = {};
    destinationsList.value = [];
    currentDestination.value = {};
    currentEditor.value = null;
    poisList.value = {};
    localStorage.clear();
    router.push({ name: 'Login' });
  };
  const token = () => {
    if (!decrypteData(Cookies.get('currentEditor'))) {
      clearApp();
      return null;
    }
    return decrypteData(Cookies.get('currentEditor')).token;
  };

  const createUserCookie = () => {
    Cookies.remove('currentEditor');
    Cookies.set('currentEditor', encryptedEditor.value);
    setCurrentEditor();
  };

  const authenticateEditor = async (email, password) => {
    try {
      const res = await authenticate(email, password);
      encryptedEditor.value = encrypteData(JSON.stringify(res));
      createUserCookie();
      const org = await OrganizationById(res.organization.id, res.token);
      currentOrganization.value = org;
      authenticated.value = true;
      return true;
    } catch (error) {
      authenticated.value = false;
      currentOrganization.value = null;
      errorNotify('Authentication error', error);
      return false;
    }
  };

  const getDestinations = async () => {
    // fetch the destination
    const res = await fetchDestinations(token());
    res.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    // make a lightweight list of the destinations
    const newList = res.map((d) => ({
      id: d.id,
      uuid: d.uuid,
      name: d.name,
      platformType: d.platformType,
      customerType: d.customerType,
      translationsCount: d.locales ? d.locales.length : 0,
      locales: d.locales,
      picture: d.picture,
      domains: d.domains,
      enableTranslations: d.enableTranslations,
      defaultLanguage: d.defaultLanguage,
      latitude: d.latitude,
      longitude: d.longitude,
    }));
    // store the destinations list
    destinationsList.value = newList;
  };
  const getPOIs = async (destinationId, destinationUuid) => {
    const res = await fetchPois(destinationId, token());
    res.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    // make a lightweight list of the destinations
    const newList = res.map((p) => ({
      id: p.id,
      uuid: p.uuid,
      destinationId: p.siteId,
      name: p.name,
      vip: p.requiresMinimumBalance === true,
      temporary: p.isTemporary === true,
      translationsCount: p.locales ? Object.keys(p.locales).length : 0,
      picture:
        p.pictures && p.pictures.length > 0
          ? p.pictures[0].filename
          : 'default.png',
      enabled: p.enabled === true,
    }));
    poisList.value[destinationUuid] = newList;
  };
  const setCurrentDestination = (destination) => {
    currentDestination.value = destination;
  };

  const hasAdminRights = computed(() => {
    if (!currentEditor.value) {
      return false;
    }
    if (currentEditor.value.isAdmin) {
      return true;
    }
    return false;
  });

  return {
    authenticated,
    currentEditor,
    destinationsList,
    currentOrganization,
    poisList,
    currentDestination,
    hasAdminRights,
    versusUsedQuestionsList,
    chatGlobalMessages,
    chatMessagesRead,
    wsIsDisconnected,
    token,
    authenticateEditor,
    setCurrentDestination,
    getDestinations,
    getPOIs,
    decrypteData,
    clearApp,
    setCurrentEditor,
  };
});

export default useAppStore;
