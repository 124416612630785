import cfg from '@/config';
import http from '../utils/fetch';

export const OrganizationById = async (id, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations/${id}`;
  const res = await http.getJSON(url, token);
  return res;
};

export const GetOrganizations = async (token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations`;
  const res = await http.getJSON(url, token);
  return res;
};

export const createOrganization = async (data, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations`;
  const res = await http.postJSON(url, data, token);
  return res;
};

export const getDestinations = async (orgId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations/${orgId}/destinations`;
  const res = await http.getJSON(url, token);
  return res;
};

export const updateOrganization = async (orgId, data, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations/${orgId}`;
  const res = await http.putJSON(url, data, token);
  return res;
};

export const associateDestination = async (orgId, destinationId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations/${orgId}/destinations/${destinationId}`;
  const res = await http.postJSON(url, {}, token);
  return res;
};

export const dissociateDestination = async (orgId, destinationId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/organizations/${orgId}/destinations/${destinationId}`;
  const res = await http.deleteJSON(url, token);
  return res;
};
