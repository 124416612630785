// Composables
import { createI18n } from 'vue-i18n';
import messages from '@/i18n/index';
import registerPlugins from '@/plugins';
import * as Sentry from '@sentry/vue';
import VuePlyr from 'vue-plyr';
import App from './App.vue';
import router from './router';
import 'vue-plyr/dist/vue-plyr.css';

import '@tabler/core/dist/css/tabler.min.css';
import 'inter-ui/inter.css';
import './assets/style/tabler_override.css';

const l = navigator.language || navigator.userLanguage;
const lang = l.substring(0, 2);

const i18n = createI18n({
  locale: lang,
  fallbackLocale: 'fr_FR',
  silentTranslationWarn: true,
  globalInjection: true,
  messages,
  warnHtmlMessage: false,
});
const i18nForJs = i18n;
export default i18nForJs;

const app = createApp(App);
if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: 'https://c276e98a00afdd4a9569806ea36cf235@o238725.ingest.sentry.io/4506059268358144',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(i18n);
app.use(VuePlyr, {
  plyr: {},
});
registerPlugins(app);

app.mount('#app');
