import cfg from '@/config';
import http from '../utils/fetch';

export const fetchDestinations = async (token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/destinations`;
  const res = await http.getJSON(url, token);
  return res;
};

export const getDestination = async (uuid, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/destinations/by-uuid/${uuid}`;
  const res = await http.getJSON(url, token);
  return res;
};

export const updateDestination = (dest, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/destinations/${dest.id}`;
  return http.putJSON(url, dest, token);
};

export const postDestination = (dest, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/destinations`;
  return http.postJSON(url, dest, token);
};
